import request from '@/utils/request'

const corporation_authApi = {
  corporation_auth_list: '/corporation_auth/',
  corporation_auth_create: '/corporation_auth/',
  corporation_auth_delete: '/corporation_auth/'
}

/**
 * 列表
 */
export function corporation_auth_list (parameter) {
  return request({
    url: corporation_authApi.corporation_auth_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function corporation_auth_create (parameter) {
  return request({
    url: corporation_authApi.corporation_auth_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function corporation_auth_delete (corporation_auth_id) {
  return request({
    url: corporation_authApi.corporation_auth_delete + corporation_auth_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
